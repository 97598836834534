@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply text-primary font-secondary bg-white;
    }

    .App {
      @apply text-center min-h-screen flex flex-col;
  }

    .h1 {
        @apply text-[45px] lg:text-[60px]
        font-primary font-bold capitalize
        leading-[120%] tracking-[-0.05em] mb-6;
    }

    .section {
        @apply min-h-screen w-screen;
    }

    .btn {
        @apply py-[10px] px-[30px] h-[50px]
        flex items-center justify-center
        text-base uppercase font-secondary
        font-semibold bg-primary text-white;
    }

    .btnHome {
      @apply py-[10px] px-[30px] h-[50px]
      flex items-center justify-center
      text-base uppercase font-secondary
      font-semibold bg-white text-black;
  }

    .checkbox {
        @apply px-[3px] text-link_primary;
    }

    .error {
        color: red;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 2rem; 
        margin-bottom: -30px;
    } 

    .success {
        color: green;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 2rem; 
        margin-bottom: -30px;
    } 
}
