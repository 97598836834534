/* carousel.css */

/* Container für das gesamte Carousel */
.carousel-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

/* Liste der Carousel-Items */
.carousel-container .carousel-list .carousel-item {
    width: 180px;
    height: 250px;
    position: absolute;
    top: 80%;
    transform: translateY(-70%);
    left: 70%;
    border-radius: 40px;
    box-shadow: 25px 25px 25px -12px rgb(255, 255, 255);
    background-position: 1% 50%;
    background-size: cover;
    z-index: 100;
    transition: 1s;
    
}

/* Stile für die verschiedenen Carousel-Items */
.carousel-container .carousel-list .carousel-item:nth-child(1),
.carousel-container .carousel-list .carousel-item:nth-child(2) {
    top: 0;
    left: 0;
    transform: translate(0, 0);
    border-radius: 0;
    width: 100%;
    height: 100%;
}

.carousel-container .carousel-list .carousel-item:nth-child(3) {
    left: 67%;
}

.carousel-container .carousel-list .carousel-item:nth-child(4) {
    left: calc(67% + 200px);
}

.carousel-container .carousel-list .carousel-item:nth-child(5) {
    left: calc(67% + 400px);
}

.carousel-container .carousel-list .carousel-item:nth-child(6) {
    left: calc(67% + 600px);
}

.carousel-container .carousel-list .carousel-item:nth-child(n+7) {
    left: calc(67% + 800px);
    opacity: 0;
    
}

/* Carousel Content */
.carousel-container .carousel-list .carousel-item .carousel-content {
    position: absolute;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);
    width: 400px;
    text-align: left;
    color: #000000;
    display: none;
    
    
}

.carousel-container .carousel-list .carousel-item:nth-child(2) .carousel-content {
    display: block;
}

.carousel-container .carousel-content .carousel-title {
    font-size: 40px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: bold;
    line-height: 1;
    opacity: 0;
    animation: animate 1s ease-in-out 0.3s 1 forwards;
    font-family: 'Playfair Display', serif;
    
}

.carousel-container .carousel-content .carousel-name {
    font-size: 50px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1;
    text-shadow: 3px 4px 4px rgba(255, 255, 255, 0.8);
    opacity: 0;
    animation: animate 1s ease-in-out 0.6s 1 forwards;
}

.carousel-container .carousel-content .carousel-des {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 20px;
    color: #ffffff;
    margin-left: 5px;
    opacity: 0;
    animation: animate 1s ease-in-out 0.9s 1 forwards;
    /* color: #000; */
    text-wrap: wrap;
}

.carousel-container .carousel-content .carousel-btn {
    margin-left: 5px;
    opacity: 0;
    animation: animate 1s ease-in-out 1.2s 1 forwards;
}

.carousel-container .carousel-content .carousel-btn button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border: 2px solid #fff;
}

.carousel-container .carousel-content .carousel-btn button:nth-child(1) {
    margin-right: 15px;
}

.carousel-container .carousel-content .carousel-btn button:nth-child(2) {
    background: transparent;
    color: #14ff72cb;
    border: 2px solid #fff;
    transition: 0.3s;
}

.carousel-container .carousel-content .carousel-btn button:nth-child(2):hover {
    background-color: #14ff72cb;
    color: #fff;
    border-color: #14ff72cb;
    
}

@keyframes animate {
    from {
        opacity: 0;
        transform: translate(0, 100px);
        filter: blur(33px);
    }
    to {
        opacity: 1;
        transform: translate(0);
        filter: blur(0);
    }
}

/* next prev arrows */
.carousel-container .arrows {
    position: absolute;
    top: 80%;
    right: 52%;
    z-index: 100;
    width: 300px;
    max-width: 30%;
    display: flex;
    gap: 10px;
    align-items: center;
}

.carousel-container .arrows button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #14ff72cb;
    color: #fff;
    border: none;
    outline: none;
    font-size: 16px;
    font-family: monospace;
    font-weight: bold;
    transition: .5s;
    cursor: pointer;
}

.carousel-container .arrows button:hover {
    background: #fff;
    color: #000;
}

/* time running */
.carousel-container .timeRunning {
    position: absolute;
    z-index: 1000;
    width: 0%;
    height: 4px;
    background-color: #14ff72cb;
    left: 0;
    top: 0;
    animation: runningTime 7s linear 1 forwards;
}

@keyframes runningTime {
    from {
        width: 0%;
    }
    to {
        width: 100%;
    }
}

/* Responsive Design */
@media screen and (max-width: 999px) {
    .carousel-container .carousel-list .carousel-item .carousel-content {
        left: 10px;
        
    }

    .carousel-container .carousel-content .carousel-title, 
    .carousel-container .carousel-content .carousel-name {
        font-size: 30px;
    }

    .carousel-container .carousel-content .carousel-des {
        font-size: 16px;
    }
}

@media screen and (max-width: 690px) {
    .carousel-container .carousel-list .carousel-item .carousel-content {
        top: 40%;   
        
        
    }

    .carousel-container .carousel-content .carousel-title, 
    .carousel-container .carousel-content .carousel-name {
        font-size: 20px;
    }

    .carousel-container .carousel-content .carousel-btn button {
        padding: 10px 15px;
        font-size: 14px;
    }
}





